import React from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";

import "./time.css";

export default function Time() {
  const { t } = useTranslation();

  return (
    <div className="timeTitleSection">
      <div className="timeSection">
        <div>
          <Icon icon="et:clock" style={{ fontSize: "60px" }} />
          <div className="timeTexts">
            <h3>{t("workload")}:</h3>
            <h4>{t("timeWorkload")}</h4>
          </div>
        </div>

        <div className="coursePeriod">
          <p>{t("courseStarts")}</p>
          <p>{t("courseEnds")}</p>
          <p>
            {t("degreeAwarded")} {t("degreeAwarded_2")}
          </p>
        </div>
      </div>
    </div>
  );
}
