import React from "react";
import { useTranslation } from "react-i18next";

import "./team.css";
import eduardo from "../../img/eduardo.png";
import ernesto from "../../img/ernesto.png";
import gabriele from "../../img/gabriele.png";
import hugo from "../../img/hugo.png";
import jessica from "../../img/jessica.png";
import marcus from "../../img/marcus.png";
import marina from "../../img/marina.png";
import natalia from "../../img/natalia.png";

export default function Team() {
  const { t } = useTranslation();
  const fotos = [
    {
      imagem: marina,
      nome: "Marina Borges",
      link: 'http://lattes.cnpq.br/6910873135038220'
    },
    {
      imagem: hugo,
      nome: "Hugo Matos",
      link: "http://lattes.cnpq.br/7037424124534181"
    },
    {
      imagem: jessica,
      nome: "Jessica Passos",
      link: "http://lattes.cnpq.br/2513420708357825"
    },
    {
      imagem: eduardo,
      nome: "Eduardo Campolongo",
      link: "http://lattes.cnpq.br/9338645529032731"
    },
    {
      imagem: ernesto,
      nome: "Ernesto Bueno",
      link: "http://buscatextual.cnpq.br/buscatextual/visualizacv.do?metodo=apresentar&id=K4419572D3"
    },
    {
      imagem: marcus,
      nome: "Marcus Vinicius",
      link: "http://lattes.cnpq.br/9928808002885294"
    },
    {
      imagem: natalia,
      nome: "Natalia Queiroz",
      link: "http://lattes.cnpq.br/0596645519861791"
    },
    {
      imagem: gabriele,
      nome: "Gabriele Landim",
      link: "http://lattes.cnpq.br/8489380996993463"
    },
  ];

  return (
    <div className="teamSection" id="team">
      <h2>{t("team")}</h2>
      <div className="teamCards">
        {fotos.map((card) => (
          <div>
            <a href={card.link} target="_blank">
              <img src={card.imagem} />
              <p>{card.nome}</p>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}
