import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";
import classNames from "classnames";

import "./mainSection.css";
import logoBranca from "../../img/logoBranca.png";

const languages = [
  {
    code: "pt",
    name: "Português",
    country_code: "br",
  },
  {
    code: "en",
    name: "English",
    country_code: "us",
  },
];

export default function MainSection() {
  const currentLanguageCode = cookies.get("i18next") || "pt";
  const { t } = useTranslation();

  const [showNavBar, setShowNavBar] = useState(false);

  const toggleMenu = () => {
    setShowNavBar(!showNavBar);
  };

  return (
    <div className="divMainSection">
      <nav className="responsiveHeaderSection">
        <div className="responsiveMenuHeader">
          <button onClick={toggleMenu}>
            <div className="responsiveOpenMenu">
              <Icon
                style={{ fontSize: "25px", color: "#FFF" }}
                icon="bytesize:menu"
              />
            </div>
          </button>
        </div>
      </nav>

      {showNavBar && (
        <nav className="responsiveLinksMenu">
          <ul>
            <li>
              <a className="navOptions" href="#course">
                {t("course")}
              </a>
            </li>
            <div className="dot"></div>
            <li>
              <a className="navOptions" href="#team">
                {t("team")}
              </a>
            </li>
            <div className="dot"></div>
            <li>
              <a className="navOptions" href="#modules">
                {t("modules")}
              </a>
            </li>
            <div className="dot"></div>
            <li>
              <a className="navOptions" href="#info">
                {t("info")}
              </a>
            </li>
            <div className="dot"></div>
            <li>
              <button
                className="btn"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="nameLanguage">
                  <p>{currentLanguageCode}</p>
                </div>
              </button>

              <ul className="dropdown-menu" aria-labelledby="">
                <li>
                  <span className="dropdown-item-text">{t("language")}</span>
                </li>
                {languages.map(({ code, name, country_code }) => (
                  <li key={country_code}>
                    <a
                      href="#"
                      className={classNames("dropdown-item", {
                        disabled: currentLanguageCode === code,
                      })}
                      onClick={() => {
                        i18next.changeLanguage(code);
                      }}
                    >
                      <span
                        className={`mx-2`}
                        style={{
                          opacity: currentLanguageCode === code ? 0.5 : 1,
                        }}
                      ></span>
                      {name}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </nav>
      )}

      <div className="mainSection" id="mainSection">
        <nav className="originalBar">
          <ul>
            <li>
              <a className="navOptions" href="#course">
                {t("course")}
              </a>
            </li>
            <li>
              <a className="navOptions" href="#team">
                {t("team")}
              </a>
            </li>
            <li>
              <a className="navOptions" href="#modules">
                {t("modules")}
              </a>
            </li>
            <li>
              <a className="navOptions" href="#info">
                {t("info")}
              </a>
            </li>
            <li>
              <button
                className="btn"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="nameLanguage">
                  <p>{currentLanguageCode}</p>
                </div>
              </button>

              <ul className="dropdown-menu" aria-labelledby="">
                <li>
                  <span className="dropdown-item-text">{t("language")}</span>
                </li>
                {languages.map(({ code, name, country_code }) => (
                  <li key={country_code}>
                    <a
                      href="#"
                      className={classNames("dropdown-item", {
                        disabled: currentLanguageCode === code,
                      })}
                      onClick={() => {
                        i18next.changeLanguage(code);
                      }}
                    >
                      <span
                        className={`mx-2`}
                        style={{
                          opacity: currentLanguageCode === code ? 0.5 : 1,
                        }}
                      ></span>
                      {name}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </nav>

        <section>
          <div className="contentMainSection">
            <img src={logoBranca} />

            <div className="textsMainSection">
              <h3>{t("dpa")}</h3>
              <h5>{t("post_graduate")}</h5>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
