import React from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";

import "./cardModule.css";
import courseWorkloadImg from "../../../img/courseWorkload.png";
import course_2 from "../../../img/course_2.jpg";
import course_3 from "../../../img/course_3.png";
import course_4 from "../../../img/course_4.jpg";
import course_5 from "../../../img/course_5.png";
import course_6 from "../../../img/course_6.jpg";
import course_7 from "../../../img/course_7.jpg";
import course_8 from "../../../img/course_8.jpg";
import course_9 from "../../../img/course_9.jpg";
import course_10 from "../../../img/course_10.png";
import course_11 from "../../../img/course_11.png";
import course_13 from "../../../img/course_13.png";
import course_14 from "../../../img/course_14.jpg";
import course_15 from "../../../img/course_15.jpeg";

export default function CardModule({ activeModal, closeModal }) {
  const { t } = useTranslation();

  const courseWorkload = [2, 4, 5, 6, 15, 21, 24, 26, 27, 30];

  const modalInfos = [
    {
      show: false,
      id: 1,
      title: t("course_title") + t("modules_subtitle"),
      workload:
        courseWorkload[6] +
        t("course_workload") +
        courseWorkload[1] +
        t("course_workload_2"),
      description: t("course_description"),
      img: courseWorkloadImg,
    },
    {
      show: false,
      id: 2,
      title: t("course_title") + t("modules_subtitle_2"),
      workload:
        courseWorkload[9] +
        t("course_workload") +
        courseWorkload[3] +
        t("course_workload_2"),
      description: t("course_description_2"),
      img: course_2,
    },
    {
      show: false,
      id: 3,
      title: t("course_title") + t("modules_subtitle_3"),
      workload:
        courseWorkload[9] +
        t("course_workload") +
        courseWorkload[3] +
        t("course_workload_2"),
      description: t("course_description_3"),
      img: course_3,
    },
    {
      show: false,
      id: 4,
      title: t("course_title") + t("modules_subtitle_4"),
      workload:
        courseWorkload[8] +
        t("course_workload") +
        courseWorkload[2] +
        t("course_workload_2"),
      description: t("course_description_4"),
      img: course_4,
    },
    {
      show: false,
      id: 5,
      title: t("course_title") + t("modules_subtitle_5"),
      workload: courseWorkload[5] + t("course_workload") + courseWorkload[1] + t("course_workload_2"),
      description: t("course_description_5"),
      img: course_5,
    },
    {
      show: false,
      id: 6,
      title: t("course_title") + t("modules_subtitle_6"),
      workload: courseWorkload[4] + t("course_workload") + courseWorkload[1] + t("course_workload_2"),
      description: t("course_description_6"),
      img: course_6
    },
    {
      show: false,
      id: 7,
      title: t("course_title_2") + t("modules_subtitle_7"),
      workload: courseWorkload[6] + t("course_workload") + courseWorkload[1] + t("course_workload_2"),
      description: t("course_description_7"),
      img: course_7,
    },
    {
      show: false,
      id: 8,
      title: t("course_title_2") + t("modules_subtitle_8"),
      workload: courseWorkload[8] + t("course_workload") + courseWorkload[2] + t("course_workload_2"),
      description: t("course_description_8"),
      img: course_8,
    },
    {
      show: false,
      id: 9,
      title: t("course_title_2") + t("modules_subtitle_9"),
      workload: courseWorkload[9] + t("course_workload") + courseWorkload[3] + t("course_workload_2"),
      description: t("course_description_9"),
      img: course_9,
    },
    {
      show: false,
      id: 10,
      title: t("course_title_2") + t("modules_subtitle_10"),
      workload: courseWorkload[9] + t("course_workload") + courseWorkload[3] + t("course_workload_2"),
      description: t("course_description_10"),
      img: course_10,
    },
    {
      show: false,
      id: 11,
      title: t("course_title_2") + t("modules_subtitle_11"),
      workload: courseWorkload[0] + t("course_workload") + courseWorkload[1] + t("course_workload_2"),
      description: t("course_description_11"),
      img: course_11,
    },
    {
      show: false,
      id: 12,
      title: t("course_title_2") + t("modules_subtitle_12"),
      workload: courseWorkload[4] + t("course_workload") + courseWorkload[1] + t("course_workload_2"),
      description: t("course_description_12"),
      img: course_14,
    },
    {
      show: false,
      id: 13,
      title: t("course_title_3") + t("modules_subtitle_13"),
      workload: courseWorkload[9] + t("course_workload") + courseWorkload[3] + t("course_workload_2"),
      description: t("course_description_13"),
      img: course_13,
    },
    {
      show: false,
      id: 14,
      title: t("course_title_3") + t("modules_subtitle_14"),
      workload: courseWorkload[5] + t("course_workload") + courseWorkload[1] + t("course_workload_2"),
      description: t("course_description_14"),
      img: course_15,
    },
    {
      show: false,
      id: 15,
      title: t("course_title_3") + t("modules_subtitle_15"),
      workload: courseWorkload[4] + t("course_workload") + courseWorkload[1] + t("course_workload_2"),
      description: t("course_description_15"),
      img: course_14,
    },
  ];

  const activeModalInfo = modalInfos[activeModal];

  return (
    <div>
      {activeModalInfo && (
        <div className="cardModuleSection" key={activeModalInfo.id}>
          <div className="cardsModule">
            <div className="titleButton">
              <button onClick={closeModal}>
                <Icon icon="fa6-solid:x" style={{ fontSize: "22px" }} />
              </button>
            </div>

            <h2>{t("menu")}</h2>

            <div className="cardModuleInfos">
              <div>
                <h5>{activeModalInfo.title}</h5>
                <h5>
                  {t("workload")}: {activeModalInfo.workload}
                </h5>
                <p>{activeModalInfo.description}</p>
              </div>
              <div className="cardModuleImg" >
                <img src={activeModalInfo.img} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
