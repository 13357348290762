import React from "react";
import { useTranslation } from "react-i18next";

import "./goalMethodology.css";
import imageMethodology from "../../img/methodology.png";
import responsiveImg from "../../img/responsiveImg.jpg";

export default function GoalMethodology() {
  const { t } = useTranslation();

  return (
    <div className="GoalMethodologySection">
      <div className="goalSection">
        <h2>{t("goal")}</h2>

        <div className="goalTexts">
          <p>{t("goal_text_1")}</p>
          <p>{t("goal_text_2")}</p>
          <p>{t("goal_text_3")}</p>
        </div>
      </div>
      
      <div className="responsiveImg">
        <img src={responsiveImg} />
      </div>
      <div className="methodologySection">
        <div className="methodologyTexts">
          <h2>{t("methodology")}</h2>

          <p className="methodologyTitle">{t("methodology_title")}</p>
          <p className="methodologyDescription">
            {t("methodology_description")}
          </p>

          <p className="methodologyTitle">{t("methodology_title_2")}</p>
          <p className="methodologyDescription">
            {t("methodology_description_2")}
          </p>

          <p className="methodologyTitle">{t("methodology_title_3")}</p>
          <p className="methodologyDescription">
            {t("methodology_description_3")}
          </p>

          <p className="methodologyTitle">{t("methodology_title_4")}</p>
          <p className="methodologyDescription">
            {t("methodology_description_4")}
          </p>
        </div>

        <img className="originalImage" src={imageMethodology} />
      </div>
    </div>
  );
}
