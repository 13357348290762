import React from "react";
import { useTranslation } from "react-i18next";

import "./areas.css";

export default function Areas() {
  const { t } = useTranslation();

  return (
    <div className="areasSection">
      <h2>{t("professional")}</h2>

      <div className="cardsAreas">
        <div className="cardArea">
          <h4>{t("card_title")}</h4>
          <p>{t("card_description")}</p>
        </div>
        <div className="cardArea">
          <h4>{t("card_title_2")}</h4>
          <p>{t("card_description_2")}</p>
        </div>
        <div className="cardArea">
          <h4>{t("card_title_3")}</h4>
          <p>{t("card_description_3")}</p>
        </div>
      </div>
    </div>
  );
}
