import React from "react";
import { useTranslation } from "react-i18next";

import "./students.css";
import anaLauraNoce from "../../img/anaLauraNoce.png";
import ariadnaCaux from "../../img/ariadnaCaux.png";
import ariegineFerreira from "../../img/ariegineFerreira.png";
import polianaCardoso from "../../img/polianaCardoso.png";
import vitoriaLopes from "../../img/vitoriaLopes.png";
import diegoGorges from "../../img/diegoGorges.png";
import fernandoFedalto from "../../img/fernandoFedalto.png";
import renatoMachado from "../../img/renatoMachado.png";
import tiagoViegas from "../../img/tiagoViegas.png";
import robertoFialho from "../../img/robertoFialho.png";
import verliVieira from "../../img/verliVieira.png";
import nataliaArreguy from "../../img/nataliaArreguy.png";
import mapa from "../../img/mapa.jpg";

export default function Students() {
  const { t } = useTranslation();
  const fotos = [
    {
      imagem: anaLauraNoce,
      address: "Belo Horizonte, BR",
      nome: "Ana Laura Noce",
    },
    {
      imagem: ariadnaCaux,
      address: "Belo Horizonte, BR",
      nome: "Ariadne Caux",
    },
    {
      imagem: ariegineFerreira,
      address: "Belo Horizonte, BR",
      nome: "Ariegine Ferreira",
    },
    {
      imagem: nataliaArreguy,
      address: "Belo Horizonte, BR",
      nome: "Natalia Arreguy",
    },
    {
      imagem: polianaCardoso,
      address: "Juiz de Fora, BR",
      nome: "Poliana Cardoso",
    },
    {
      imagem: vitoriaLopes,
      address: "Santos, BR",
      nome: "Vitória Lopes",
    },
    {
      imagem: diegoGorges,
      address: "Camboriú, BR",
      nome: "Diego Gorges",
    },
    {
      imagem: fernandoFedalto,
      address: "Curitiba, BR",
      nome: "Fernando Fedalto",
    },

    {
      imagem: renatoMachado,
      address: "London, UK",
      nome: "Renato Machado",
    },
    {
      imagem: robertoFialho,
      address: "São Paulo, BR",
      nome: "Robeto Fialho",
    },
    {
      imagem: tiagoViegas,
      address: "Belo Horizonte, BR",
      nome: "Tiago Viegas",
    },
    {
      imagem: verliVieira,
      address: "Brasília, BR",
      nome: "Verli Vieira",
    },
  ];

  return (
    <div className="studentsSection">
      <h2>{t("students")}</h2>
      <h4>{t("class")}</h4>
      <div className="mapStudents">
        <img className="map" src={mapa} />
        <div className="studentsCards">
          {fotos.map((card) => (
            <div>
              <img src={card.imagem} />
              <p>{card.nome}</p>
              <h6>{card.address}</h6>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
