import React from "react";
import { Icon } from "@iconify/react";
import "./styles.css";

export default function ArrowIcon() {
  return (
    <div className="arrowIcon">
      <a href="#mainSection">
        <Icon icon="eva:arrow-ios-upward-fill" />
      </a>
    </div>
  );
}
