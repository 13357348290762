import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

import MainSection from "./components/mainSection/MainSection";
import AboutCourse from "./components/aboutCourse/AboutCourse";
import Time from "./components/time/Time";
import GoalMethodology from "./components/goalMethodology/GoalMethodology";
import Areas from "./components/areas/Areas";
import Team from "./components/team/Team";
import Students from "./components/students/Students";
import Modules from "./components/modules/Modules";
import Footer from "./components/footer/Footer";
import ArrowIcon from "./components/ArrowIcon";
import Schedule from "./components/schedule/Schedule";

const languages = [
  {
    code: "pt",
    name: "Português",
    country_code: "br",
  },
  {
    code: "en",
    name: "English",
    country_code: "us",
  },
];

export default function App() {
  const currentLanguageCode = cookies.get("i18next") || "pt";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    console.log("Setting page stuff");
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title") + " - " + t("dpa");
  }, [currentLanguage, t]);

  return (
    <div>
      <ArrowIcon/>
      <MainSection />
      <AboutCourse />
      <Time />
      <GoalMethodology />
      <Areas />
      <Team />
      <Students />
      <Modules />
      <Schedule/>
      <Footer/>
    </div>
  );
}
