import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CardModule from './cardModule/CardModule';
import './modules.css';
import './cardModule/cardModule.css';

export default function Modules() {
  const [openModal, setOpenModal] = useState(false);
  const [activeModal, setActiveModal] = useState();

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = (index) => {
    setOpenModal(true);
    setActiveModal(index);
  };

  const { t } = useTranslation();
  const modulesInfos = [
    {
      title: t('form'),
      courses: [
        t('modules_subtitle'),
        t('modules_subtitle_2'),
        t('modules_subtitle_3'),
        t('modules_subtitle_4'),
        t('modules_subtitle_5'),
        t('modules_subtitle_6'),
      ],
      module: t('module') + ' I',
    },
    {
      title: t('materiality'),
      courses: [
        '', '', '', '', '', '', 
        t('modules_subtitle_7'),
        t('modules_subtitle_8'),
        t('modules_subtitle_9'),
        t('modules_subtitle_10'),
        t('modules_subtitle_11'),
        t('modules_subtitle_12'),
      ],
      module: t('module') + ' II',
    },
    {
      title: t('tectonics'),
      courses: [
        '', '', '', '', '', '',  '', '', '', '', '', '',
        t('modules_subtitle_13'),
        t('modules_subtitle_14'),
        t('modules_subtitle_15'),
      ],
      module: t('module') + ' III',
    },
  ];

  return (
    <div className="modulesSection" id="modules">
      <div className="titleModules">
        <h2>{t('module')}</h2>
      </div>
      
      <div className="textsModules">
        <p>{t('modules_text')}</p>
        <p>{t('modules_text_2')}</p>
        <p>{t('modules_text_3')}</p>
      </div>
      {openModal && (
        <CardModule activeModal={activeModal} closeModal={handleCloseModal} />
      )}
      <div className="cardsMap">  
        {modulesInfos.map((card) => (
          <>
            <div className="cardModule">
              <h4>{card.title}</h4>

              <div className="cardButtons">
                {card.courses.map((card, index) => (
                  <button key={index} onClick={() => handleOpenModal(index)}>
                    {card}
                  </button>
                ))}
              </div>

              <div className="nameModule">
                <h5>{card.module}</h5>
              </div>
            </div>
          </>
        ))}
        
      </div>
    
    </div>
  );
}
