import React from "react";
import { useTranslation } from "react-i18next";

import "./aboutCourse.css";

export default function AboutCourse() {
  const { t } = useTranslation();

  return (
    <div className="aboutCourseSection" id="course">
      <div className="aboutTexts">
        <h2>{t("the_course")}</h2>

        <div className="aboutContent">
          <p>{t("about_course")}</p>
          <br />
          <p>{t("about_course_2")}</p>
        </div>
      </div>

      <div className="aboutButton">
        <a href="https://www.pucminas.br/Pos-Graduacao/IEC/Cursos/Paginas/Design%20Parametrico%20em%20Arquitetura_Pos%20Online_Especializacao%20e%20Master.aspx?pageID=4078&moda=5&modaTipo=2&polo=40&curso=1147&situ=1" target="_blank">
          {t('subscribe')}
        </a>
      </div>
    </div>
  );
}
