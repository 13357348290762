import React from "react";
import { useTranslation } from "react-i18next";

import "./schedule.css";

export default function Schedule() {
  const { t } = useTranslation();

  return (
    <div className="scheduleSection" id="info">
      <div className="infosSchedule">
        <div className="scheduleTexts">
          <p>{t("virtualClasses")}</p>
          <p className="weekDays">{t("timeClasses")}</p>
          <p className="weekDays">{t("timeClasses_2")}</p>
        </div>
        <a href="https://www.pucminas.br/Pos-Graduacao/IEC/Cursos/Paginas/Design%20Parametrico%20em%20Arquitetura_Pos%20Online_Especializacao%20e%20Master.aspx?pageID=4078&moda=5&modaTipo=2&polo=40&curso=1147&situ=1" target="_blank">
          {t("subscribe")}
        </a>
      </div>    
    </div>
  );
}
