import React from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "@iconify/react";
import logoDpa from "../../img/logoPreto.png";
import logoPuc from "../../img/logoPuc.png";
import "./footer.css";

export default function Footer() {
  const { t } = useTranslation();
  var data = new Date();
  var ano = data.getFullYear();

  return (
    <div className="footerSection">
      <div className="contentAndImages">
        <div className="contentIcons">
          <h3>{t("contact")}</h3>
          <p>design.parametrico.arquitetura@gmail.com</p>
          <ul>
            <li>
              <a target="_blank" href="https://www.instagram.com/design.parametrico.pucminas/">
                <Icon icon="radix-icons:instagram-logo" />
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.facebook.com/Design-Param%C3%A9trico-Arquitetura-106189947993707">
                <Icon icon="entypo-social:facebook-with-circle" />
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.linkedin.com/in/design-param%C3%A9trico-em-arquitetura-7b000a22a/">
                <Icon icon="ant-design:linkedin-filled" />
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.youtube.com/channel/UCA91tcnmmRlbzY0G_j31lxg">
                <Icon icon="ant-design:youtube-filled" />
              </a>
            </li>
            <li>
              <a target="_blank" href="https://open.spotify.com/show/3H1gbtm3PkcmLUHrI9Bp1e?si=a4SgRW9TTOiWXOF839wHaw&nd=1">
                <Icon icon="akar-icons:spotify-fill" />
              </a>
            </li>
          </ul>
        </div>

        <div>
          <img src={logoDpa} />
          <img src={logoPuc} />
        </div>
      </div>

      <p>
        &copy; {ano} {t("contentFooter")}
      </p>
    </div>
  );
}
